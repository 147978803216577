<template>
  <section>
    <Navbar />
    <div class="columns">
      <SideBarHome class="menu" />
      <div class="container">
        <vs-row class="topo">
          <div class="titulo">
            <font-awesome-icon :icon="['fas', 'window-maximize']" />
            <h2 class="tituloAtendimento">Cancelamento</h2>
          </div>
        </vs-row>

        <div v-if="readyForm == true">
          <div>
            <ValidationObserver ref="observer2" v-slot="{ invalid }">
              <vs-row class="pd-t">
                <vs-col>
                  <fieldset class="search">
                    <legend>Informe o RA <b style="color: red;">*</b></legend>
                    <ValidationProvider
                      name="RA"
                      rules="required|numeric"
                    >
                      <vs-input
                        @keyup.enter="getCancelDataByRA()"
                        v-model="ra"
                        class="selectExampleRA"
                        placeholder="Digite o RA do Aluno"
                      />
                    </ValidationProvider>
                    <vs-button
                      color="primary"
                      class="buscar"
                      type="filled"
                      :disabled="invalid"
                      @click="getCancelDataByRA()"
                    >
                      <font-awesome-icon :icon="['fas', 'search']" />
                      <span> Buscar </span></vs-button
                    >
                  </fieldset>
                </vs-col>
              </vs-row>
            </ValidationObserver>
          </div>

          <ValidationObserver ref="observer" v-slot="{ invalid }">
            <div v-if="responseRa != null">
              <div v-if="responseRa == ''">
                <p class="validacao">RA Inválido</p>
              </div>
              <div v-else>
                <vs-row class="pd-table">
                  <vs-col>
                    <fieldset>
                      <legend>
                        Selecione o Curso para {{ responseRa[0].NOMEALUNO }}
                      </legend>
                      <ValidationProvider
                        name="Curso"
                        rules="required"
                      >
                        <vs-table
                          class="table_intranet"
                          hoverFlat
                          v-model="selectedCourse"
                          :data="removeDuplicates(responseRa,'CURSO')"
                          v-on:input="selectedItens()"
                        >
                          <template slot="thead">
                            <vs-th class="table_th"> Curso </vs-th>
                            <vs-th class="table_th"> Filial </vs-th>
                            <vs-th class="table_th"> Polo </vs-th>
                          </template>

                          <template slot-scope="{ data }">
                            <vs-tr
                              :data="tr"
                              :key="indextr"
                              class="table_body"
                              v-for="(tr, indextr) in data"
                            >
                              <vs-td>
                                {{ data[indextr].CURSO }}
                              </vs-td>

                              <vs-td>
                                {{ data[indextr].FILIAL }}
                              </vs-td>

                              <vs-td
                                v-if="data[indextr].POLO == '[object Object]'"
                              >
                              </vs-td>

                              <vs-td v-else>
                                {{ data[indextr].POLO }}
                              </vs-td>
                            </vs-tr>
                          </template>
                        </vs-table>
                      </ValidationProvider>
                    </fieldset>
                  </vs-col>
                </vs-row>

                <div v-if="selectedCourse != null">
                  <vs-row class="pd-t">
                    <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                      <fieldset>
                        <legend>Aluno <b style="color: red;">*</b></legend>
                        <vs-input
                          class="selectExampleDouble"
                          :placeholder="nameStudent"
                          disabled="disabled"
                        />
                      </fieldset>
                    </vs-col>

                    <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                      <fieldset>
                        <legend>RA do Aluno <b style="color: red;">*</b></legend>
                        <vs-input
                          class="selectExampleDouble"
                          :placeholder="raStudent"
                          disabled="disabled"
                        />
                      </fieldset>
                    </vs-col>
                  </vs-row>

                  <vs-row class="pd-t">
                    <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                      <fieldset>
                        <legend>Curso <b style="color: red;">*</b></legend>
                        <vs-input
                          class="selectExampleDouble"
                          :placeholder="course"
                          disabled="disabled"
                        />
                      </fieldset>
                    </vs-col>

                    <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                      <fieldset>
                        <legend>Responsável Financeiro <b style="color: red;">*</b></legend>
                        <vs-input
                          class="selectExampleDouble"
                          :placeholder="financialResponsible"
                          disabled="disabled"
                        />
                      </fieldset>
                    </vs-col>
                  </vs-row>

                  <vs-row class="pd-t">
                    <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                      <fieldset>
                        <legend>Documento Responsável Financeiro <b style="color: red;">*</b></legend>
                        <vs-input
                          class="selectExampleDouble"
                          :placeholder="documentFinancialResponsible"
                          disabled="disabled"
                        />
                      </fieldset>
                    </vs-col>

                    <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                      <fieldset>
                        <legend>E-mail Responsável Financeiro <b style="color: red;">*</b></legend>
                        <vs-input
                          class="selectExampleDouble"
                          :placeholder="emailFinancialResponsible"
                          disabled="disabled"
                        />
                      </fieldset>
                    </vs-col>
                  </vs-row>

                  <vs-row class="pd-t">
                    <vs-col>
                      <ValidationProvider
                        name="Número(s) do(s) Contrato(s)"
                        rules="required"
                      >
                        <vs-textarea
                          rows="11"
                          label="Número(s) do(s) Contrato(s) *"
                          placeholder="Separar os Contratos por (;)"
                          class="textArea"
                          height="10em"
                          counter="1000"
                          v-model="numberOfContracts"
                        />
                      </ValidationProvider>
                    </vs-col>
                  </vs-row>

                  <vs-row class="pd-t">
                    <vs-col>
                      <ValidationProvider
                        name="Disciplinas"
                        rules="required"
                      >
                        <vs-textarea
                          rows="11"
                          label="Disciplinas *"
                          class="textArea"
                          height="10em"
                          counter="1000"
                          v-model="subject"
                        />
                      </ValidationProvider>
                    </vs-col>
                  </vs-row>

                  <vs-row class="pd-t">
                    <vs-col>
                      <fieldset>
                        <legend>Aulas Decorridas <b style="color: red;">*</b></legend>
                        <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                          <fieldset>
                            <legend>Total de Horas (Decorridas) <b style="color: red;">*</b></legend>
                            <ValidationProvider
                              name="Total de Horas"
                              rules="required"
                            >
                              <vs-input 
                                v-model="hourNotPaid" 
                                type="number" 
                                placeholder="0" 
                                class="selectExampleDouble"
                              />
                            </ValidationProvider>
                          </fieldset>
                        </vs-col>
                        <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                          <fieldset>
                            <legend>Valor da Hora <b style="color: red;">*</b></legend>
                            <ValidationProvider
                              name="Valor da Hora"
                              rules="required"
                            >
                              <vs-input 
                                v-model="hourValue" 
                                type="number" 
                                placeholder="0,00" 
                                class="selectExampleDouble"
                              />
                            </ValidationProvider>
                          </fieldset>
                        </vs-col>
                      </fieldset>
                    </vs-col>
                  </vs-row>

                  <vs-row class="pd-t">
                    <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                      <fieldset>
                        <legend>Multa de Cancelamento <b style="color: red;">*</b></legend>
                        <ValidationProvider
                          name="Multa de Cancelamento"
                          rules="required"
                        >
                          <vs-input 
                            v-model="cancellationFine" 
                            type="number" 
                            placeholder="0,00" 
                            class="selectExampleDouble"
                          />
                        </ValidationProvider>
                      </fieldset>
                    </vs-col>
                    <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                      <fieldset>
                        <legend>Valor Pago <b style="color: red;">*</b></legend>
                        <ValidationProvider
                          name="Valor Pago"
                          rules="required"
                        >
                          <vs-input 
                            v-model="amountPaid" 
                            type="number" 
                            placeholder="0,00" 
                            class="selectExampleDouble"
                          />
                        </ValidationProvider>
                      </fieldset>
                    </vs-col>
                  </vs-row>

                  <vs-row class="pd-t">
                    <vs-col vs-lg="6" vs-sm="12" vs-xs="12">
                      <fieldset>
                        <legend>Data (1ª parcela) <b style="color: red;">*</b></legend>
                        <ValidationProvider
                          name="Data (1ª parcela)"
                          rules="required"
                        >
                          <vs-input
                            class="selectExampleDouble"
                            type="date"
                            v-model="dateParcel"
                          />
                        </ValidationProvider>
                      </fieldset>
                    </vs-col>
                    <vs-col class="pd-c" vs-lg="6" vs-sm="12" vs-xs="12">
                      <fieldset>
                        <legend>Parcelas <b style="color: red;">*</b></legend>
                        <ValidationProvider
                          name="Parcelas"
                          rules="required"
                        >
                          <vs-input
                            v-model="parcels" 
                            type="number"
                            v-mask="'##'"
                            placeholder="Quantidade de Parcelas (Ex. 10)" 
                            class="selectExampleDouble"
                          />
                        </ValidationProvider>
                      </fieldset>
                    </vs-col>
                  </vs-row>

                  <vs-row>
                    <div>
                      <h4 style="margin-left: 15px;">Valor Total: <span>R$ {{ Number(amountOwed).toFixed(2) }}</span>
                        <vs-button
                          style="margin-left: 7.5px;" 
                          @click="showValue()"
                        >
                          <font-awesome-icon :icon="['fas', 'calculator']" />
                          Calcular
                        </vs-button>
                      </h4>
                    </div>
                  </vs-row>

                  <vs-row class="pd-t">
                    <vs-col>
                      <ValidationProvider
                        name="Motivo"
                        rules="required"
                      >
                        <vs-textarea
                          rows="11"
                          label="Motivo *"
                          class="textArea"
                          height="15em"
                          counter="1000"
                          v-model="reason"
                        />
                      </ValidationProvider>
                    </vs-col>
                  </vs-row>

                  <vs-row class="pd-t">
                    <vs-col style="text-align: center;">
                      <vs-button
                        color="success"
                        type="gradient"
                        class="button-protocol"
                        :disabled="invalid"
                        @click.native="createEmailText()"
                        >Gerar
                      </vs-button>

                      <vs-button
                        color="danger"
                        type="gradient"
                        class="button-protocol"
                        @click.native="resetForm()"
                        >Cancelar
                      </vs-button>
                    </vs-col>
                  </vs-row>
                </div>
              </div>
            </div>
          </ValidationObserver>
        </div>

        <div v-if="readyForm == false">
          <ValidationObserver ref="observer" v-slot="{ invalid }">
            <vs-row class="pd-t">
              <vs-col>
                <fieldset>
                  <legend>Documento Via E-mail <b style="color: red;">*</b></legend>
                  <vs-textarea
                    :placeholder="emailText"
                    class="textArea"
                    disabled="disabled"
                    rows="45"
                  />
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col>
                <fieldset>
                  <ValidationProvider
                    name="Confirme que os Dados estão Corretos"
                    rules="required"
                  >
                    <input
                      type="checkbox"
                      id="index"
                      class="checkboxTurn"
                      v-model="checkbox"
                      value="Eu li o Documento Via E-mail e os dados estão corretos."
                    />
                    <label for="index"
                      >Eu li o Documento Via E-mail e os dados estão
                      corretos.</label
                    >
                  </ValidationProvider>
                </fieldset>
              </vs-col>
            </vs-row>

            <vs-row class="pd-t">
              <vs-col style="text-align: center;">
                <vs-button
                  color="success"
                  type="gradient"
                  class="button-protocol"
                  :disabled="invalid"
                  @click="sendEmailTerms()"
                  >Gerar
                </vs-button>

                <vs-button
                  color="danger"
                  type="gradient"
                  class="button-protocol"
                  @click.native="readyForm = true"
                  >Voltar
                </vs-button>
              </vs-col>
            </vs-row>
          </ValidationObserver>
        </div>

        <div v-if="successMessage">
          <vs-row class="pd-t">
            <vs-col>
              <fieldset>
                <p>
                  TERMOS ENVIADOS COM SUCESSO PARA O EMAIL DO RESPONSÁVEL
                  FINANCEIRO
                </p>
              </fieldset>
            </vs-col>
          </vs-row>

          <vs-button
            style="width: 97.5%; margin: 0 1.25% 10px 1.25% !important;"
            type="gradient" 
            @click.native="resetForm()"
          >
            <font-awesome-icon :icon="['fas', 'chevron-circle-left']" />
            Recomeçar
          </vs-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Students from "../services/students";
import { convertDate } from "../helpers/storage";
import Email from "../services/email";
export default {
  name: "TermoCancelamento",
  data: () => ({
    ra: null,
    responseRa: null,
    errorResponseRa: null,
    selectedCourse: null,
    nameStudent: null,
    raStudent: null,
    financialResponsible: null,
    documentFinancialResponsible: null,
    emailFinancialResponsible: null,
    course: null,
    polo: null,
    emailPolo: null,
    codFilial: null,
    numberOfContracts: null,
    subject: null,
    hourNotPaid: null,
    hourValue: null,
    classNotPaid: null,
    cancellationFine: null,
    amountOwed: null,
    creditLetter: null,
    amountTotal: null,
    dateParcel: null,
    amountPaid: null,
    parcels: null,
    reason: null,
    readyForm: true,
    emailText: null,
    checkbox: [],
    successMessage: false,
    codEmail: null,
    errorCodEmail: null,
    emailTextHtml: null,
  }),

  methods: {
    getCancelDataByRA() {
      this.$vs.loading({ text: "Carregando..." });
      new Students()
        .getCancelDataByRA(this.ra)
        .then(({ data }) => {
          this.responseRa = Array.isArray(data) ? data : [data];
          this.$vs.loading.close();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.errorResponseRa = response.data;
            console.log(response);
          }
          this.$vs.loading.close();
        });
    },

    removeDuplicates(items, campo) {
      let noDuplicates = [];
      for(let i=0; i<items.length; i++){
        let duplicate = false;
        for(let j=0; j<noDuplicates.length; j++){
          if(campo=="CURSO" && items[i].CODCURSO == noDuplicates[j].CODCURSO){
            duplicate = true;
          }
        }

        if(!duplicate){
          noDuplicates.push(items[i]);
        }
      }
      
      noDuplicates.sort((a, b) => a.CURSO.localeCompare(b.CURSO));
      
      return noDuplicates;    
    },

    selectedItens() {
      this.nameStudent = this.selectedCourse.NOMEALUNO;
      this.raStudent = this.selectedCourse.RA;
      this.course = this.selectedCourse.CURSO;
      this.financialResponsible = this.selectedCourse.RESPONSAVELFINANCEIRO;
      this.documentFinancialResponsible = this.selectedCourse.DOCUMENTORESPONSAVEL;
      this.emailFinancialResponsible = this.selectedCourse.EMAILRESPONSAVEL;
      this.codFilial = this.selectedCourse.CODFILIAL;
      this.polo = this.selectedCourse.EMAILPOLO ? this.selectedCourse.POLO : this.selectedCourse.FILIAL;
      this.emailPolo = this.selectedCourse.EMAILPOLO ? this.selectedCourse.EMAILPOLO : this.selectedCourse.EMAILFILIAL;
    },

    showValue() {
      this.classNotPaid = Number(this.hourNotPaid) * Number(this.hourValue);
      this.amountOwed = Number(this.classNotPaid) + Number(this.cancellationFine) - Number(this.amountPaid);
    },

    createEmailText() {
      this.readyForm = false;

      this.showValue();
      this.creditLetter = this.amountOwed < 0 ? Number(Math.abs(Number(this.amountOwed).toFixed(2))).toFixed(2) : '0.00';
      this.amountTotal = this.amountOwed < 0 ? '0.00' : Number(this.amountOwed).toFixed(2);

      this.emailText = this.amountTotal != '0.00' ?
        "Olá aluno " +
        this.financialResponsible +
        ",\n" +
        "\n\nRecebemos o seu interesse em cancelar o(s) contrato(s) nº " +
        this.numberOfContracts +
        ", curso " +
        this.course +
        ", na Filial/Polo: " +
        this.polo +
        "\n\nMotivo do cancelamento:" +
        "\n" +
        this.reason +
        "\n\nEsclarecemos que de acordo com o contrato firmado, o pedido de cancelamento do curso acarreta a cobrança de todas as aulas decorridas até o dia da solicitação de cancelamento que ainda não tenham sido integralmente pagas no parcelamento do valor total, mais a multa de 10% do saldo cancelado do contrato." +
        "\n\nDessa forma, encaminhamos os valores rescisórios de seu contrato:" +
        "\n\nAulas decorridas: R$ " +  Number(this.classNotPaid).toFixed(2) +
        "\nMulta de cancelamento: R$ " + Number(this.cancellationFine).toFixed(2) +
        "\nCarta de crédito: R$ " + this.creditLetter +
        "\nValor pago: R$ " + Number(this.amountPaid).toFixed(2) +
        "\nValor total devido: R$ " + this.amountTotal +
        "\n\nTais valores poderão ser parcelados, da seguinte forma: R$ " + Number(this.amountOwed).toFixed(2) + " parcelado em " + this.parcels + "x de R$ " + Number(Number(this.amountOwed).toFixed(2) / Number(this.parcels)).toFixed(2)  + ", onde a 1ª parcela ficou para " + convertDate(this.dateParcel) + ", sendo pago na mesma forma de pagamento do contrato." +
        "\n\nAguardamos sua confirmação de interesse na rescisão, devendo responder este e-mail com a anuência expressa quanto aos valores, para validação do TERMO DE CANCELAMENTO." +
        "\n\nO não pagamento dos valores atinentes ao cancelamento implicará na cobrança extrajudicial e/ou judicial do débito existente, bem como na possibilidade de inscrição junto aos órgãos de restrição ao crédito." +
        "\n\nAtenção: salientamos que a ausência de resposta a este e-mail em até 48 horas úteis será interpretado como desistência do pedido de cancelamento e sua matrícula permanecerá ativa." +
        "\n\nFicamos inteiramente à disposição para eventuais esclarecimentos." +
        "\n\nAtenciosamente," +
        "\nQI Faculdade & Escola Técnica" : 
        "Olá aluno " +
        this.financialResponsible +
        ",\n" +
        "\n\nRecebemos o seu interesse em cancelar o(s) contrato(s) nº " +
        this.numberOfContracts +
        ", curso " +
        this.course +
        ", na Filial/Polo: " +
        this.polo +
        "\n\nMotivo do cancelamento:" +
        "\n" +
        this.reason +
        "\n\nEsclarecemos que de acordo com o contrato firmado, o pedido de cancelamento do curso acarreta a cobrança de todas as aulas decorridas até o dia da solicitação de cancelamento que ainda não tenham sido integralmente pagas no parcelamento do valor total, mais a multa de 10% do saldo cancelado do contrato." +
        "\n\nDessa forma, encaminhamos os valores rescisórios de seu contrato:" +
        "\n\nAulas decorridas: R$ " +  Number(this.classNotPaid).toFixed(2) +
        "\nMulta de cancelamento: R$ " + Number(this.cancellationFine).toFixed(2) +
        "\nValor pago: R$ " + Number(this.amountPaid).toFixed(2) +
        "\nValor total devido: R$ " + this.amountTotal +
        "\nCarta de crédito: R$ " + this.creditLetter +
        "\n\nAtenção: salientamos que a ausência de resposta a este e-mail em até 48 horas úteis será interpretado como desistência do pedido de cancelamento e sua matrícula permanecerá ativa." +
        "\n\nFicamos inteiramente à disposição para eventuais esclarecimentos." +
        "\n\nAtenciosamente," +
        "\nQI Faculdade & Escola Técnica";

      this.emailTextHtml = this.amountTotal != '0.00' ?
        "<p>Olá aluno " +
        this.financialResponsible +
        ",</p>" +
        "<p>Recebemos o seu interesse em cancelar o(s) contrato(s) nº " +
        this.numberOfContracts +
        ", curso " +
        this.course +
        ", na Filial/Polo: " +
        this.polo +
        "<p>Motivo do cancelamento:<br />" +
        this.reason +
        "</p>" +
        "<p>Esclarecemos que de acordo com o contrato firmado, o pedido de cancelamento do curso acarreta a cobrança de todas as aulas decorridas até o dia da solicitação de cancelamento que ainda não tenham sido integralmente pagas no parcelamento do valor total, mais a multa de 10% do saldo cancelado do contrato.</p>" +
        "<p>Dessa forma, <strong>encaminhamos os valores rescisórios de seu contrato:</strong></p> <br/>" +
        "<p>Aulas decorridas: R$ " + Number(this.classNotPaid).toFixed(2) + "</p>" +
        "<p>Multa de cancelamento: R$ " + Number(this.cancellationFine).toFixed(2) + "</p>" +
        "<p>Carta de crédito: R$ " + this.creditLetter + "</p>" +
        "<p>Valor pago: R$ " + Number(this.amountPaid).toFixed(2) + "</p>" +
        "<p><strong>Valor total devido: R$ " + Number(this.amountTotal).toFixed(2) + "</strong></p> <br/>" +
        "<p>Tais valores poderão ser parcelados, da seguinte forma: <strong>R$ " +
        Number(this.amountOwed).toFixed(2) +
        " parcelado em " +
        this.parcels +
        "x de R$" +
        Number(Number(this.amountOwed).toFixed(2) / Number(this.parcels)).toFixed(2) +
        ", onde a 1ª parcela ficou para " + 
        convertDate(this.dateParcel) + 
        ", sendo pago na mesma forma de pagamento do contrato.</strong></p>" +
        "<p><strong>Aguardamos sua confirmação de interesse na rescisão, devendo responder este e-mail com a anuência expressa quanto aos valores, para validação do TERMO DE CANCELAMENTO.</strong></p>" +
        "<p>O não pagamento dos valores atinentes ao cancelamento implicará na cobrança extrajudicial e/ou judicial do débito existente, bem como na possibilidade de inscrição junto aos órgãos de restrição ao crédito.</p>" +
        "<p><strong>Atenção: salientamos que a ausência de resposta a este e-mail em até 48 horas úteis será interpretado como desistência do pedido de cancelamento e sua matrícula permanecerá ativa.</strong></p>" +
        "<p>Ficamos inteiramente à disposição para eventuais esclarecimentos.</p>" +
        "<br />" +
        "<p>Atenciosamente,<br />" +
        "QI Faculdade & Escola Técnica</p>" : 
        "<p>Olá aluno " +
        this.financialResponsible +
        ",</p>" +
        "<p>Recebemos o seu interesse em cancelar o(s) contrato(s) nº " +
        this.numberOfContracts +
        ", curso " +
        this.course +
        ", na Filial/Polo: " +
        this.polo +
        "<p>Motivo do cancelamento:<br />" +
        this.reason +
        "</p>" +
        "<p>Esclarecemos que de acordo com o contrato firmado, o pedido de cancelamento do curso acarreta a cobrança de todas as aulas decorridas até o dia da solicitação de cancelamento que ainda não tenham sido integralmente pagas no parcelamento do valor total, mais a multa de 10% do saldo cancelado do contrato.</p>" +
        "<p>Dessa forma, <strong>encaminhamos os valores rescisórios de seu contrato:</strong></p> <br/>" +
        "<p>Aulas decorridas: R$ " + Number(this.classNotPaid).toFixed(2) + "</p>" +
        "<p>Multa de cancelamento: R$ " + Number(this.cancellationFine).toFixed(2) + "</p>" +
        "<p>Valor pago: R$ " + Number(this.amountPaid).toFixed(2) + "</p>" +
        "<p>Valor total devido: R$ " + this.amountTotal + "</p>" +
        "<p><strong>Carta de crédito: R$ " + this.creditLetter + "</strong></p> <br/>" +
        "<p><strong>Atenção: salientamos que a ausência de resposta a este e-mail em até 48 horas úteis será interpretado como desistência do pedido de cancelamento e sua matrícula permanecerá ativa.</strong></p>" +
        "<p>Ficamos inteiramente à disposição para eventuais esclarecimentos.</p>" +
        "<br />" +
        "<p>Atenciosamente,<br />" +
        "QI Faculdade & Escola Técnica</p>";
    },

    sendEmailTerms() {
      this.$vs.loading({ text: "Carregando..." });
      const payload = {
        emailText: this.emailText,
        emailTextHtml: this.emailTextHtml,
        emailFinancialResponsible: this.emailFinancialResponsible,
        nameFinancialResponsible: this.financialResponsible,
        nameBranch: this.polo,
        emailBranch: this.emailPolo,
      };

      new Email()
      .sendEmailCancellation(payload)
      .then(({ data }) => {
        this.codEmail = data;
        this.successMessage = true;
        this.readyForm = null;
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.errorCodEmail = response.data;
          console.log(response);
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });
    },

    resetForm() {
      this.ra = null;
      this.responseRa = null;
      this.selectedCourse = null;
      this.nameStudent = null;
      this.raStudent = null;
      this.course = null;
      this.classNotPaid = null;
      this.cancellationFine = null;
      this.hourNotPaid = null;
      this.hourValue = null;
      this.amountOwed = null;
      this.creditLetter = null;
      this.amountTotal = null;
      this.amountPaid = null;
      this.financialResponsible = null;
      this.documentFinancialResponsible = null;
      this.emailFinancialResponsible = null;
      this.dateParcel = null;
      this.polo = null;
      this.emailPolo = null;
      this.numberOfContracts = null;
      this.subject = null;
      this.parcels = null;
      this.reason = null;
      this.readyForm = true;
      this.successMessage = false;
      this.checkbox = [];
      this.emailTextHtml = null;
      this.emailText = null;

      this.$nextTick(() => {
        this.$refs.observer.reset();
        this.$refs.observer2.reset();
      });
    },
  },
};
</script>

<style scoped>
.input-horario {
  width: 5.6em;
}

tr:nth-child(even) {
  background-color: #d4d4d4;
}

@media screen and (max-width: 650px) {
  .input-horario {
    width: 5em;
  }
}

@media (max-width: 570px) {
  .boxTurn {
    flex-direction: column;
  }
}

@media (max-width: 530px) {
  .checkboxTurn {
    width: 2.6em;
  }

  .horario {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 480px) {
  .buscar span {
    display: none;
  }

  .buscar {
    width: 3em;
    margin-left: 0.7em;
  }

  .horario > span {
    margin-left: 0.5em;
  }
}

@media screen and (max-width: 410px) {
  .checkboxTurn {
    font-size: 0.9em;
  }
}
</style>