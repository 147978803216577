export default class {
    constructor() {
        this.instance = 
            [     
                {                           
                    label: "Atendimentos",
                    url: "/modulos/atendimentos",
                    icon: "headset",  
                    type: "internal",
                },
                {                           
                    label: "Matrícula Web",
                    url: "https://matricula.qi.edu.br/",
                    icon: "graduation-cap",  
                    type: "external",
                },
                {
                    label: "Alunos/Turma",
                    url: "/listar-alunos",
                    icon: "chalkboard",
                    type: "internal",
                },
                {
                    label: "Cancelamento",
                    url: "/cancelamento",
                    icon: "ban",
                    type: "internal",
                },
                {
                    label: "Ramal QI",
                    url: "/ramais",
                    icon: "phone-square",
                    type: "internal",
                },
                {
                    label: "Solicitar Convênio",
                    url: "/modulos/atendimentos/administracao/registro-de-convenio",
                    icon: "folder-plus",
                    type: "internal",
                },                
                {
                    label: "Empresas Conveniadas",
                    url: "/empresas-conveniadas",
                    icon: "folder-open",
                    type: "internal",
                },
                {
                    label: "Moodle",
                    url: "https://ead.qi.edu.br/login/index.php",
                    icon: "university",
                    type: "external",
                },
                /*{                           
                    label: "SAC",
                    url: "http://sistemas.qi.edu.br/sac",
                    icon: "headphones",  
                    type: "external",
                },*/
                {                           
                    label: "EducaMobile",
                    url: "https://qifaculdade132663.rm.cloudtotvs.com.br/EducaMobile/Account/Login",
                    icon: "tablet",  
                    type: "external",
                },
                {
                    label: "Catálogo da Biblioteca",
                    url: "https://qifaculdade132663.rm.cloudtotvs.com.br/Corpore.Net/Login.aspx?autoload=false",
                    icon: "book-open",
                    type: "external",
                },
                {
                    label: "Biblioteca Pearson",
                    url: "https://plataforma.bvirtual.com.br/Account/Login",
                    icon: "book",
                    type: "external",
                },
                {
                    label: "Treinamento",
                    url: "https://drive.google.com/drive/folders/1Fd98SIqqWxuYyTZvJN1vNkVCnejMn8TQ",
                    icon: "object-group",
                    type: "external",
                },
                {                           
                    label: "QI Wiki",
                    url: "https://wiki.qi.edu.br",
                    icon: "globe",  
                    type: "external",
                },
                {
                    label: "Calendário Letivo",
                    url: "https://docs.google.com/spreadsheets/d/1NTSooR5x4mxQTi_hp139a81bg-6CUhAW/edit#gid=559688562",
                    icon: "calendar-day",
                    type: "external",
                },
                {
                    label: "Cronogramas Cursos Técnicos",
                    url: "https://drive.google.com/drive/folders/12OM4hfdhlgRy9850u0yv54pLlVkcj7aj",
                    icon: "graduation-cap",
                    type: "external",
                },
                {
                    label: "Quadro de Ofertas",
                    url: "https://docs.google.com/spreadsheets/d/1J8Ty9I0QQ6MXjDY3wQNBaRrJWvduzh84jF_sMPZtDGY/edit#gid=0",
                    icon: "file-invoice",
                    type: "external",
                },
                {
                    label: "Eventos Marketing",
                    url: "https://docs.google.com/spreadsheets/d/1zau-wnbRRn-1tQT06lTNCI85GvqZCiPQYFOwaNOUdhI/edit#gid=0 ",
                    icon: "bullseye",
                    type: "external",
                },
                {                           
                    label: "Meu RH",
                    url: "https://qifaculdade132663.rm.cloudtotvs.com.br/FrameHTML//Web/App/RH/PortalMeuRH/",
                    icon: "users",  
                    type: "external",
                },
                {
                    label: "Normativas",
                    url: "http://normativas.qi.edu.br/",
                    icon: "book-open",
                    type: "external",
                },
                {                           
                    label: "Central de Vagas",
                    url: "https://vagas.qi.edu.br",
                    icon: "address-book",  
                    type: "external",
                },
                {                           
                    label: "Modelo Importação Mailing",
                    url: "https://docs.google.com/spreadsheets/d/1aRVjg2t0d4tLGANNcr85cuXfIWRVD5jsstfWBCvumt0/edit#gid=919429985",
                    icon: "list",  
                    type: "external",
                },
                {                           
                    label: "Baixar RM",
                    url: "https://qifaculdade132663.rm.cloudtotvs.com.br:8059/wsupdate/",
                    icon: "download",  
                    type: "external",
                },
                {                           
                    label: "CallSys",
                    url: "https://qi.espectra.com.br/pages/login",
                    icon: "brain",  
                    type: "external",
                },
            ]
        }
    getData(){
        return this.instance;
    }
}